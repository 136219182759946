import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ShineLoader from './components/ShineLoader';
import Breadcrumbs from './components/Breadcrumbs';

const LotusFlower = lazy(() => import('./components/LotusFlower'));
const HealYourMind = lazy(() => import('./pages/HealYourMind'));
const FreeYourMind = lazy(() => import('./pages/FreeYourMind'));
const EnjoyHappiness = lazy(() => import('./pages/EnjoyHappiness'));
const DailyPractice = lazy(() => import('./pages/DailyPractice'));
const MeditationTimer = lazy(() => import('./pages/MeditationTimer'));
const UnderstandYourMind = lazy(() => import('./pages/UnderstandYourmind'));
const UnderstandLife = lazy(() => import('./pages/UnderstandLife'));
const TheNatureAndProcessOfLife = lazy(() => import('./pages/TheNatureAndProcessOfLife'));
const Question = lazy(() => import('./pages/QuestionPage'));

function App() {
  return (
    <Router>
      <ConditionalBreadcrumbs /> {/* Display Breadcrumbs conditionally */}
      <Suspense fallback={<ShineLoader />}>
        <Routes>
          <Route path="/" element={<LotusFlower />} />
          <Route path="/HealYourMind" element={<HealYourMind />} />
          <Route path="/EnjoyHappiness" element={<EnjoyHappiness />} />
          <Route path="/FreeYourMind" element={<FreeYourMind />} />
          <Route path="/DailyPractice" element={<DailyPractice />} />
          <Route path="/DailyPractice/MeditationTimer" element={<MeditationTimer />} />
          <Route path="/UnderstandYourMind" element={<UnderstandYourMind />} />
          <Route path="/UnderstandLife" element={<UnderstandLife />} />
          <Route path="/TheNatureAndProcessOfLife" element={<TheNatureAndProcessOfLife />} />
          <Route path="/UnderstandYourMind/Question" element={<Question />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

function ConditionalBreadcrumbs() {
  const location = useLocation();
  if (location.pathname === '/') {
    return null;
  }
  return <Breadcrumbs />;
}

export default App;
