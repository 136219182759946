import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumbs.css'; // Ensure this path is correct

function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Define your breadcrumb paths and names
  const breadcrumbMap = {
    "/": { name: "Home", icon: "fa-home" },
    "/HealYourMind": { name: "Heal Your Mind", icon: "fa-leaf" },
    "/FreeYourMind": { name: "Free Your Mind", icon: "fa-bolt" },
    "/EnjoyHappiness": { name: "Enjoy Happiness", icon: "fa-smile" },
    "/DailyPractice": { name: "Daily Practice", icon: "fa-calendar" },
    "/UnderstandYourMind": { name: "Understand Your Mind", icon: "fa-brain" },
    "/UnderstandLife": { name: "Understand Life", icon: "fa-life-ring" },
    "/TheNatureAndProcessOfLife": { name: "The Nature and Process of Life", icon: "fa-globe" },
  };

  return (
    <nav aria-label="breadcrumb" className="breadcrumb-nav">
      <ul className="breadcrumb-list">
        {/* Home Link */}
        <li key="home" className="breadcrumb-item">
          <Link
            to="/"
            className={`breadcrumb-link ${location.pathname === "/" ? "breadcrumb-active" : ""}`}
            aria-current={location.pathname === "/" ? "page" : undefined}
          >
            <i className="fa fa-home" aria-hidden="true"></i> Home
          </Link>
        </li>

        {/* Render each segment of the path as a breadcrumb */}
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const breadcrumb = breadcrumbMap[to] || {};
          const displayName = breadcrumb.name || value.charAt(0).toUpperCase() + value.slice(1);

          return (
            <li key={to} className="breadcrumb-item">
              <Link
                to={to}
                className={`breadcrumb-link ${location.pathname === to ? "breadcrumb-active" : ""}`}
                aria-current={location.pathname === to ? "page" : undefined}
              >
                <i className={`fa ${breadcrumb.icon || 'fa-circle'}`} aria-hidden="true"></i> {displayName}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
